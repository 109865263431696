<template>
  <section class="app-sidebar">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav" v-if="userprofile.type == 2">
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/">
            <i class="ti-home menu-icon"></i>
            <span class="menu-title">Job Tickets</span>
          </router-link>
        </li>
        <li class="nav-item cursor-pointer">
          <span class="nav-link" v-b-toggle="'sites'">
            <i class="menu-icon"><font-awesome-icon icon="building"/></i>
            <span class="menu-title">Sites</span>
            <i class="menu-arrow"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="sites">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/sites">PoP Sites</router-link>
              </li>
              <li class="nav-item">
                <a class="nav-link" @click="getUserCheckins()">Site Check-In</a>
                <siteCheckinModal modalID="siteCheckinModal" :checkedInSite ="checkoutsActive ? getSite(checkoutList[0].siteid) : null"/>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item cursor-pointer">
          <span class="nav-link" v-b-toggle="'equipment'">
            <i class="menu-icon"><font-awesome-icon icon="server"/></i>
            <span class="menu-title">Equipment</span>
            <i class="menu-arrow"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="equipment">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/equipment/inventory">Spare Parts Inventory</router-link>
              </li>           
              <li class="nav-item">
                <router-link class="nav-link" to="/equipment">Equipment</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item" v-on:click="collapseAll" >
          <router-link class="nav-link" to="/general-pages/customer-faq">
            <i class="menu-icon"><font-awesome-icon icon="building"/></i>
            <span class="menu-title">FAQ & Knowledgebase</span>          
          </router-link>
        </li>
        <li class="nav-item cursor-pointer text-center d-flex d-md-none">
          <a :href="`tel:1300837638`" class="nav-link">
            <button type="button" class="btn btn-inverse-primary btn-fw"><i class="mx-0 mr-1">
              <font-awesome-icon icon="phone"></font-awesome-icon>
            </i> 1300 837 638
            </button>
          </a>
        </li>
      </ul>









      <ul class="nav" v-else>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/">
            <i class="ti-home menu-icon"></i>
            <span class="menu-title">Dashboard</span>
          </router-link>
        </li>
        <li class="nav-item cursor-pointer">
          <span class="nav-link" v-b-toggle="'services'">
            <i class="menu-icon"><font-awesome-icon icon="ethernet"/></i>
            <span class="menu-title">Services</span>
            <i class="menu-arrow"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="services">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/services">All Services</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/services/unmanaged">Unmanaged Services ({{unmanagedservices.length}})</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/services/managed">Managed Services ({{managedservices.length}})</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/services/vpls-networks">VPLS Services ({{vplsServices.length}})</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/services/internet">Internet Services ({{internetServices.length}})</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/services/l3vpn">L3VPN Services ({{l3vpnServices.length}})</router-link>
              </li>
              <li class="nav-item" v-if="userprofile.type === 1">
                <a class="nav-link" @click="$bvModal.show('newSidebarService')">New Service</a>
                <newServiceModal modalID="newSidebarService" />
              </li>
              
              <!-- <li class="nav-item">
                <router-link class="nav-link" to="/">Cloud Connections</router-link>
              </li> -->
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item cursor-pointer">
          <span class="nav-link" v-b-toggle="'colocation'">
            <i class="menu-icon"><font-awesome-icon icon="server"></font-awesome-icon></i>
            <span class="menu-title">Co-location</span>
            <i class="menu-arrow"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="colocation">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/colocation/rackspaces">Rack Spaces</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/colocation/crossconnects">Cross-Connects</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item" v-on:click="collapseAll" v-if="userprofile.type > 3">
          <router-link class="nav-link" to="/sites">
            <i class="menu-icon"><font-awesome-icon icon="building"/></i>
            <span class="menu-title">Sites</span>
          </router-link>
        </li>
        <li class="nav-item cursor-pointer" v-else-if="userprofile.type === 1 || userprofile.type === 3">
          <span class="nav-link" v-b-toggle="'sites'">
            <i class="menu-icon"><font-awesome-icon icon="building"/></i>
            <span class="menu-title">Sites</span>
            <i class="menu-arrow"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="sites">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/sites">All Sites</router-link>
              </li>
              <li class="nav-item" v-if="userprofile.type === 1">
                <a class="nav-link" @click="$bvModal.show('newSiteModal')">Add New Site</a>
                <sitecreationform modalID="newSiteModal" />
              </li>
              <li class="nav-item" v-if="userprofile.type === 1">
                <a class="nav-link" @click="$bvModal.show('newSiteAccess')">Site Access Request</a>
                <siteAccessModal modalID="newSiteAccess" />
              </li>
              <li class="nav-item" v-if="userprofile.type === 1">
                <a class="nav-link" @click="getUserCheckins()">Site Check-In</a>
                <siteCheckinModal modalID="siteCheckinModal" :checkedInSite ="checkoutsActive ? getSite(checkoutList[0].siteid) : null"/>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item cursor-pointer" v-if="userprofile.type == 1 || userprofile.type == 3">
          <span class="nav-link" v-b-toggle="'equipment'">
            <i class="menu-icon"><font-awesome-icon icon="server"/></i>
            <span class="menu-title">Equipment</span>
            <i class="menu-arrow"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="equipment">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <a class="nav-link" @click="$bvModal.show('updateEquipment')">Update Equipment</a>
                <updateEquipmentModal modalID="updateEquipment" @EventCreated="(eventID) => newEvent(eventID)" />
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/equipment/inventory">Spare Parts Inventory</router-link>
              </li>           
              <li class="nav-item">
                <router-link class="nav-link" to="/equipment">Equipment</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/jobs">AWX Jobs</router-link>
              </li>
<!--               <li class="nav-item">
                <router-link class="nav-link" to="/general-pages/technician-job-creator">Create Technician Job</router-link>
              </li> -->
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item" v-if="userprofile.type == 1">
          <router-link class="nav-link" to="/customers">
            <i class="menu-icon fa fa-university"></i>
            <span class="menu-title">Customers</span>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/contacts">
            <i class="menu-icon fa fa-id-badge"></i>
            <span class="menu-title">Contacts</span>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/functions/state-map">
            <i class="menu-icon fa fa-map"></i>
            <span v-if="userprofile.type == 1" class="menu-title">Network Map</span>
            <span v-else class="menu-title">Network Alert Map</span>
          </router-link>
        </li>
        <li class="nav-item cursor-pointer">
          <span class="nav-link" v-b-toggle="'event-dropdown'">
            <i class="menu-icon fa fa-calendar"></i>
            <span class="menu-title">Events</span>
            <i class="menu-arrow"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="event-dropdown">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/events/scheduled-events">Scheduled Events ({{scheduledevents.length}})</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/events">Network Outages ({{problems.length}})</router-link>
              </li>
              <li class="nav-item" v-if="userprofile.type === 1">
                <a class="nav-link" @click="$bvModal.show('newSidebarEvent')">New Event</a>
                <newEventModal modalID="newSidebarEvent" @EventCreated="(eventID) => newEvent(eventID)" />
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/events/past-events">Past Outages</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item cursor-pointer">
          <span class="nav-link" v-b-toggle="'reports'">
            <i class="menu-icon fa fa-book" ></i>
            <span class="menu-title">Reports</span>
            <i class="menu-arrow"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="reports">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/documentation/sla-reports">SLA Reports</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/documentation/usage-reports">Usage Reports</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/documentation/changeman-reports">Change Management</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/documentation/invoices">Invoices</router-link>
            </li>
            </ul>
          </b-collapse>
        </li>
<!--        <li class="nav-item cursor-pointer">
          <span class="nav-link" v-b-toggle="'orders-dropdown'">
            <i class="ti-pencil-alt menu-icon"></i>
            <span class="menu-title">Orders</span>
            <i class="menu-arrow"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="orders-dropdown">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/">Request Quote</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/">Order Status</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>-->
        <li class="nav-item cursor-pointer">
          <span class="nav-link" v-b-toggle="'support-dropdown'">
            <i class="menu-icon"><font-awesome-icon icon="life-ring"/></i>
            <span class="menu-title">Support</span>
            <i class="menu-arrow"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="support-dropdown">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item" v-if="userprofile.chameleon !== 2">
                <router-link class="nav-link" to="/tickets">Tickets</router-link>
              </li>
              <li class="nav-item" v-if="userprofile.chameleon !== 2">
                <a class="nav-link" @click="$bvModal.show('newSidebarTicket')">New Ticket</a>
                <newTicketModal modalID="newSidebarTicket" @TicketCreated="(ticketID) => newTicket(ticketID)" />
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/general-pages/customer-faq">FAQ & Knowledgebase</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/general-pages/release-notes">Portal Release Notes</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item cursor-pointer" v-if="userprofile.type != 5">
          <span class="nav-link" v-b-toggle="'admin'">
            <i class="ti-clipboard menu-icon"></i>
            <span class="menu-title">Administration</span>
            <i class="menu-arrow"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="admin">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/users">Users</router-link>
              </li>
              <li v-if="userprofile.companyAdmin" class="nav-item">
                <router-link class="nav-link" to="/">Company Settings</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <li class="nav-item cursor-pointer text-center d-flex d-md-none">
          <a :href="`tel:1300837638`" class="nav-link">
            <button type="button" class="btn btn-inverse-primary btn-fw"><i class="mx-0 mr-1">
              <font-awesome-icon icon="phone"></font-awesome-icon>
            </i> 1300 837 638
            </button>
          </a>
        </li>
      </ul>
    </nav>
  </section>
</template>

<script>

import newTicketModal from "@/pages/tickets/components/newTicketModal";
import newEventModal from "@/pages/events/components/newEventModal";
import newServiceModal from "@/pages/services/components/newServiceModal";
import sitecreationform from "@/components/vernet/sitecreationform";
import siteAccessModal from "@/pages/sites/components/siteAccessModal";
import updateEquipmentModal from "@/pages/equipment/components/updateEquipmentModal";
import siteCheckinModal from "@/pages/sites/components/siteCheckinModal";

export default {
  name: "sidebar",
  components: {
    newTicketModal,
    newEventModal,
    newServiceModal,
    sitecreationform,
    siteAccessModal,
    updateEquipmentModal,
    siteCheckinModal
   },

  computed: {
    userprofile () {
      return this.$store.getters.getProfile;
    },
    customerprofile () {
      return this.$store.getters.getMyCustomerProfile;
    },
    scheduledevents() {
      try {
        var eventsList = this.$store.getters.getEventsListByType(1).concat(this.$store.getters.getEventsListByType(2));
        return eventsList
      } catch (error) {
        return this.items;
      }
    },
    problems() {
      try {
        var eventsList = this.$store.getters.getEventsListByType(3);
        return eventsList
      } catch (error) {
        return this.items;
      }
    },
    unmanagedservices(){
      try {
        var unmanageserviceList = this.$store.getters.getServicesListByType('A')
        return unmanageserviceList
      } catch (error) {
        return this.items;
      }
    },
    managedservices(){
      try {
        var manageserviceList = this.$store.getters.getServicesListByType('B').concat(this.$store.getters.getServicesListByType('C').concat(this.$store.getters.getServicesListByType('D')))
        return manageserviceList
      } catch (error) {
        return this.items;
      }
    },
    vplsServices(){
      try {
        var vplsServiceList = this.$store.getters.getServicesListByType('F').concat(this.$store.getters.getServicesListByType('W'))
        return vplsServiceList
      } catch (error) {
        return this.items;
      }
    },
    internetServices(){
      try {
        var internetServiceList = this.$store.getters.getServicesListByType('H').concat(this.$store.getters.getServicesListByType('W'))
        return internetServiceList
      } catch (error) {
        return this.items;
      }
    },
    l3vpnServices(){
      try {
        var l3vpnServiceList = this.$store.getters.getServicesListByType('G')
        return l3vpnServiceList
      } catch (error) {
        return this.items;
      }
    },

  },
  data() {
    return {
      collapses: [{ show: false }, { show: false }, { show: false }],
      items: [],
      checkoutsActive: false,
      checkoutList: []
    };
  },
  methods: {
    getSite(siteid){
        return this.$store.getters.getSite(siteid)
    },
    getUserCheckins() {
      let userCheckins = this.$store.getters.getUserCheckins(this.userprofile.userid).filter(thisCheckin => thisCheckin.checkouttime == null)
      if(userCheckins.length > 0){
        this.checkoutList = userCheckins
        this.checkoutsActive = true
      } else {
        this.checkoutList = []
        this.checkoutsActive = false
      }

      this.$bvModal.show('siteCheckinModal')
    },
    collapseAll() {
      var exp_elm = document.getElementsByClassName("show");
      if (exp_elm.length > 0) {
        var elm_id = exp_elm[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }
    },
    newTicket(ticketID) {
      this.$router.push('/ticket/'+ticketID)
      this.openTicket(ticketID)
    },
  },
  mounted() {
    const body = document.querySelector("body");
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll(".sidebar .nav-item").forEach(function(el) {
      el.addEventListener("mouseover", function() {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function() {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  },
  watch: {
    $route() {
      if(document.querySelector("#sidebar").classList.contains("active")) { document.querySelector("#sidebar").classList.toggle("active"); }
    }
  }
};
</script>
